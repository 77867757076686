<template>
    <transition name="fade">
        <!-- Test center selector -->
        <nav class="inset-0 fixed z-50 bg-gray-50 overflow-scroll" v-if="activeTenant === null && tenants.length > 0">
            <div class="bg-primary py-8 lg:py-16">
                <div class="max-w-2xl mx-auto">
                    <img :src="tenants[0]['logo']" class="mx-auto w-full h-auto px-4" alt="Testzentrum auswählen">
                </div>
            </div>

            <div class="flex justify-center items-center p-8 lg:p-0 mt-8 sm:mt-16">
                <div class="max-w-7xl flex flex-wrap -mx-8">
                    <div class="w-full md:mb-16 text-center">
                        <h1 class="text-3xl font-bold">Bitte wählen Sie Ihr Testzentrum aus:</h1>
                    </div>

                    <div class="w-full mt-8 sm:mt-16 lg:mt-0 lg:w-1/2 px-8" v-for="tenant in tenants" :key="tenant">
                        <div
                            class="shadow p-4 hover-bg-primary hover:text-white cursor-pointer transition-colors duration-200"
                            @click="activeTenant = tenant">
                            <h2 class="text-2xl font-bold mb-4" v-text="tenant['name']"></h2>
                            <address>
                                {{ tenant['addressLine1'] }}<br>
                                <template v-if="tenant['addressLine2'].length > 0">
                                    {{ tenant['addressLine2'] }}<br>
                                </template>
                                {{ tenant['postalCode'] }} {{ tenant['city'] }}
                            </address>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </transition>

    <header v-if="tenants.length > 0">
        <div class="bg-primary py-8 lg:py-16">
            <div class="max-w-2xl mx-auto">
                <img :src="tenants[0]['logo']" class="mx-auto w-full h-auto px-4" alt="Testzentrum Logo">
            </div>
        </div>
    </header>

    <template v-if="activeTenant">
        <main>
            <div class="relative overflow-hidden">
                <!-- SVG Pattern -->
                <div class="absolute inset-y-0 h-full w-full" aria-hidden="true">
                    <div class="relative h-full">
                        <svg
                            class="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
                            width="404" height="784" fill="none" viewBox="0 0 404 784">
                            <defs>
                                <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20"
                                         patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"/>
                        </svg>
                        <svg
                            class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                            width="404" height="784" fill="none" viewBox="0 0 404 784">
                            <defs>
                                <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20"
                                         patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
                                </pattern>
                            </defs>
                            <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"/>
                        </svg>
                    </div>
                </div>

                <div class="relative">
                    <div class="mt-16 pb-16 mx-auto max-w-7xl px-4 sm:mt-32 sm:pb-32 sm:px-6">
                        <div class="text-center">
                            <h1 class="text-4xl tracking-tight font-extrabold text-gray-700 sm:text-5xl md:text-6xl mb-8 lg:mb-16"
                                v-text="activeTenant['name']"></h1>

                            <h2 class="inline-block uppercase text-xl text-gray-700 font-bold mb-2 border-b border-primary w-auto mx-auto">
                                Öffnungszeiten</h2>
                            <p class="mb-16 text-3xl text-gray-700 font-bold" v-text="activeTenant['openingHours']"></p>

                            <div v-if="activeTenant['hasMotdEnabled']" class="rounded-md bg-blue-50 p-4 mb-24">
                                <p class="text-lg font-bold text-center text-blue-700" v-html="activeTenant['motdMessage']"></p>
                            </div>

                            <p class="max-w-xl mx-auto md:mt-5 flex flex-row uppercase">
                                <a :href="`https://${activeTenant['domain']}/registration`"
                                   v-if="activeTenant['hasSignupEnabled']"
                                   class="flex items-center justify-center px-8 py-3 mr-8 border border-transparent font-medium rounded-md text-white bg-primary hover:bg-blue-500 md:py-4 md:text-lg lg:text-2xl md:px-10">
                                    Registrierung
                                </a>

                                <a :href="`https://${activeTenant['domain']}/login`"
                                   class="flex items-center justify-center px-8 py-3 border border-transparent font-medium rounded-md text-white bg-primary hover:bg-blue-500 md:py-4 md:text-lg lg:text-2xl md:px-10 uppercase">
                                    Anmeldung
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Map -->
                <div class="relative">
                    <div class="absolute inset-0 flex flex-col" aria-hidden="true">
                        <div class="flex-1"></div>
                        <div class="flex-1 w-full bg-primary"></div>
                    </div>
                    <div class="max-w-7xl mx-auto px-4 sm:px-6">
                        <ol-map
                            :load-tiles-while-animating="true"
                            :load-tiles-while-interacting="true"
                            style="height: 48rem">
                            <ol-view ref="view"
                                     :center="transformCoordinates([activeTenant['longitude'], activeTenant['latitude']])"
                                     :rotation="rotation"
                                     :zoom="zoom"
                                     :projection="projection"></ol-view>

                            <ol-tile-layer>
                                <ol-source-osm/>
                            </ol-tile-layer>

                            <ol-vector-layer>
                                <ol-source-vector>
                                    <ol-feature>
                                        <ol-geom-point
                                            :coordinates="transformCoordinates([activeTenant['longitude'], activeTenant['latitude']])"></ol-geom-point>
                                        <ol-style>
                                            <ol-style-icon :src="markerIcon" :scale="0.1"></ol-style-icon>
                                        </ol-style>
                                    </ol-feature>

                                </ol-source-vector>
                            </ol-vector-layer>
                        </ol-map>
                    </div>
                </div>
            </div>
        </main>

        <footer class="bg-primary">
            <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 text-blue-200">
                <h2 class="text-center text-blue-100 text-xl font-semibold uppercase tracking-wide">Impressum</h2>

                <div class="flex flex-wrap lg:-mx-8 mt-8">
                    <div class="w-full lg:w-4/12 lg:px-8">
                        <h3 class="text-blue-100 font-semibold mb-2" v-text="activeTenant['name']"></h3>
                        <address class="not-italic">
                            {{ activeTenant['addressLine1'] }}<br>
                            <template v-if="activeTenant['addressLine2'].length > 0">
                                {{ activeTenant['addressLine2'] }}<br>
                            </template>
                            {{ activeTenant['postalCode'] }} {{ activeTenant['city'] }}
                        </address>
                        <p>Teststelle: {{ activeTenant['samplingPointNumber'] }}</p>
                    </div>

                    <div class="w-full lg:w-4/12 lg:px-8 mt-8 lg:mt-0">
                        <h3 class="text-blue-100 font-semibold mb-2">Firmensitz</h3>
                        <address class="not-italic">
                            {{ activeTenant['company'] }}<br>
                            {{ activeTenant['companyAddressLine1'] }}<br>
                            {{ activeTenant['companyPostalCode'] }} {{ activeTenant['companyCity'] }}
                        </address>
                    </div>

                    <div class="w-full lg:w-4/12 lg:px-8 mt-8 lg:mt-0">
                        <h3 class="text-blue-100 font-semibold mb-2">Kontakt</h3>
                        <p>
                            Geschäftsführer: {{ activeTenant['manager'] }}<br>
                            Ärztliche Leitung: {{ activeTenant['medicalManagement'] }}<br>
                            Tel.: <span class="font-bold text-white" v-text="activeTenant['phoneNumber']"></span><br>
                            Mail: <a :href="`mailto:${activeTenant['emailAddress']}`"
                                     v-text="activeTenant['emailAddress']"></a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </template>
</template>

<script>
import {
    ref
} from 'vue'
import axios from 'axios'
import proj4 from 'proj4'
import markerIcon from './images/marker.png'

import {TENANTS} from './tenants'

export default {
    name: 'App',

    setup() {
        const tenants = ref([])
        const activeTenant = ref(null)
        const projection = ref('EPSG:3857')
        const zoom = ref(13)
        const rotation = ref(0)

        return {
            tenants,
            activeTenant,
            projection,
            zoom,
            rotation,
            markerIcon
        }
    },

    data() {
        return {}
    },

    mounted() {
        const DEBUG = window.location.hostname === 'localhost'

        // Get correct tenants for the current hostname
        const HOST = window.location.host.replace('www.', '')

        if (!HOST) {
            console.error('Could not find host', HOST)
            return
        }

        if (!TENANTS[HOST]) {
            console.error('Host is not a valid tenant', HOST)
            return
        }

        // Get details for each tenant from the backend in parallel
        const REQUESTS = []

        if (DEBUG) {
            this.tenants = [
                {
                    "id": "X57Y8ZXYKL39R",
                    "logo": "http://mamos.visualappeal.de/api/media/tenants/logos/ronsdorf.png".replace('http:', 'https:'),
                    "name": "COVID-19 Testzentrum Ronsdorf",
                    "domain": "ronsdorf.mamos.visualappeal.de",
                    "addressLine1": "Parkstraße 85",
                    "addressLine2": "",
                    "postalCode": "42369",
                    "city": "Wuppertal",
                    "countryCode": "DE",
                    "latitude": 51.23872882056774,
                    "longitude": 7.20376993059431,
                    "emailAddress": "info@testeninronsdorf.de",
                    "phoneNumber": "0176 977 271 00 (Rückrufservice und Whatsappsupport)",
                    "samplingPointNumber": "53+137",
                    "openingHours": "Täglich von 08:00 - 20:00 Uhr",
                    "signupOptions": {
                        "support": {
                            "items": ["BTV Ronsdorf", "DT 1860 Ronsdorf", "TSV05 Ronsdorf"],
                            "title": "Unterstützter Verein",
                            "description": "Mit jedem bei uns durchgeführten Test unterstützen wir einen lokalen Verein. Wählen Sie hier, welchen Verein Sie mit Ihrem Test unterstützen möchten."
                        }
                    },
                    "hasSignupEnabled": true,
                    "mustApproveSignups": false,
                    "contactPerson": "WR7KY9ZWM3L4N",
                    "accountManager": "WR7KY9ZWM3L4N",
                    "permissions": {"create": false, "destroy": false, "write": false, "read": true},
                    "creationDate": "2021-04-27T23:19:43.350225+02:00",
                    "modificationDate": "2021-06-29T14:44:13.304619+02:00",
                    "manager": "Lars Schlingensiepen",
                    "company": "BeLa GmbH",
                    "companyAddressLine1": "Langenhaus 11",
                    "companyPostalCode": "42369",
                    "companyCity": "Remscheid",
                    "companyCountryCode": "DE",
                    "medicalManagement": "Dr. Manuel Richter",
                    "primaryColor": "#0065A7",
                    "hasMotdEnabled": true,
                    "motdMessage": "The MOTD Message"
                },
                {
                    "id": "8XPMNZPMK64WY",
                    "logo": "http://mamos.visualappeal.de/api/media/tenants/logos/ronsdorf.png".replace('http:', 'https:'),
                    "name": "COVID-19 Testzentrum Langenhaus",
                    "domain": "ronsdorf-wrs.mamos.visualappeal.de",
                    "addressLine1": "Langenhaus 11",
                    "addressLine2": "",
                    "postalCode": "42369",
                    "city": "Wuppertal",
                    "countryCode": "DE",
                    "latitude": 51.21295441373712,
                    "longitude": 7.187147460006374,
                    "emailAddress": "info@testeninronsdorf.de",
                    "phoneNumber": "0176 977 271 00 (08:00 - 22:00 Uhr // Rückrufservice und Whatsappsupport)",
                    "samplingPointNumber": "40+076",
                    "openingHours": "Mo. - So. 18:30 - 21:00 Uhr  +++NEUE ÖFFNUNGSZEITEN+++ Ab dem 01.08.2021 nur noch von Mo. bis So. 18:30 - 21:00 Uhr geöffnet!",
                    "signupOptions": {},
                    "hasSignupEnabled": true,
                    "mustApproveSignups": false,
                    "contactPerson": null,
                    "accountManager": null,
                    "permissions": {"create": false, "destroy": false, "write": false, "read": true},
                    "creationDate": "2021-04-27T23:19:43.350225+02:00",
                    "modificationDate": "2021-08-02T11:40:32.569021+02:00",
                    "manager": "Lars Schlingensiepen",
                    "company": "BeLa GmbH",
                    "companyAddressLine1": "Langenhaus 11",
                    "companyPostalCode": "42369",
                    "companyCity": "Remscheid",
                    "companyCountryCode": "DE",
                    "medicalManagement": "Dr. Manuel Richter",
                    "primaryColor": "#0065A7",
                    "hasMotdEnabled": false,
                    "motdMessage": ""
                }
            ]

            this.afterLoadSetup()
        } else {
            TENANTS[HOST].forEach((tenantId) => {
                const URL = `https://mamos.visualappeal.de/api/v1/tenants/${tenantId}?format=json`
                console.debug('Get tenant details', URL)

                REQUESTS.push(axios.get(URL))
            })

            axios.all(REQUESTS)
                .then(axios.spread((...responses) => {
                    console.info('Loaded tenants successfully', responses)

                    this.tenants = responses.map((response) => {
                        let data = response.data
                        data['logo'] = data['logo'].replace('http:', 'https:')

                        return data
                    })

                    this.afterLoadSetup()
                }))
                .catch((err) => {
                    console.error('Could not load tenants', err)
                })
        }
    },

    methods: {
        /**
         * Setup the application after all tenants are loaded
         */
        afterLoadSetup() {
            // If only one tenant is available, it is automatically active (displayed)
            if (this.tenants.length === 1) {
                this.activeTenant = this.tenants[0]
            }

            // Add tailwind type class names for the primary colors
            let style = document.createElement('style');
            /* eslint-disable no-useless-escape */
            style.innerHTML = `
                .bg-primary {
                    background-color: ${this.tenants[0]['primaryColor']};
                }
                .text-primary {
                    background-color: ${this.tenants[0]['primaryColor']};
                }
                .border-primary {
                    border-color: ${this.tenants[0]['primaryColor']};
                }

                .hover-bg-primary:hover {
                    background-color: ${this.tenants[0]['primaryColor']};
                }
                .hover-text-primary:hover {
                    background-color: ${this.tenants[0]['primaryColor']};
                }
            `;
            /* eslint-enable */
            document.head.appendChild(style);
        },

        transformCoordinates(coordinates) {
            // Transform the coordinates from 4326 (default lat/lang) to a special "Web Mercator" format which
            // looks better on open street map (less distorted)
            // @see https://en.wikipedia.org/wiki/Web_Mercator_projection
            return proj4('EPSG:4326', 'EPSG:3785', coordinates)
        }
    },

    watch: {
        activeTenant() {
            document.title = this.activeTenant['name']
        }
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
